/* You can add global styles to this file, and also import other style files */

body {
    font-family: "Montserrat"!important;
}

.text-default {
    color: #3a9e6f !important;
}

.carousel-control-next-icon {
    background-color: #b8bebce7;
    border-radius: 20px;
}

.carousel-control-prev-icon {
    background-color: #b8bebce7;
    border-radius: 20px;
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    margin-top: 20%;
    list-style: none;
}

//HEADER GOB
.inverse2 {
    background-color: #611232 !important;
    border-color: transparent !important;
}

.main-footer2 {
    background-color: #611232;
    min-height: 56px;
    color: #FFF;
    padding: 0px 0 0px;
    text-align: left;
    font-weight: 300;
}

.main-footer2 .list-info {
    line-height: auto;
    color: white;
    background-size: cover;
    background-position: bottom;
    padding: 30px 10px 10px;
}

.main-footer2 .list-info h5 {
    font-weight: 300;
    margin-bottom: 20px;
}

.main-footer2 .list-info ul {
    margin: 0 0 60px;
    padding: 0;
}

.main-footer2 .list-info ul li {
    list-style-type: none;
    margin: 0 0 2px;
    padding: 0;
    line-height: 110%;
}

.main-footer2 .list-info a {
    color: #FFF;
    font-size: 14px;
    text-decoration: none;
}

.main-footer2 .list-info p {
    margin-bottom: 15px;
}

.main-footer2 p {
    margin: 0;
    font-size: 14px;
    line-height: 136%;
    height: auto;
    position: static;
}

.main-footer2 .list-info {
    line-height: auto;
    color: white;
    background-size: cover;
    background-position: bottom;
    padding: 30px 10px 10px;
}

.main-footer2 .list-info h5 {
    font-weight: 300;
    margin-bottom: 20px;
}

.main-footer2 .list-info ul {
    margin: 0 0 60px;
    padding: 0;
}

.main-footer2 .list-info ul li {
    list-style-type: none;
    margin: 0 0 2px;
    padding: 0;
    line-height: 110%;
}

.main-footer2 .list-info a {
    color: #FFF;
    font-size: 14px;
    text-decoration: none;
}

.main-footer2 .list-info p {
    margin-bottom: 15px;
}

.main-footer2 p {
    margin: 0;
    font-size: 14px;
    line-height: 136%;
    height: auto;
    position: static;
}

.footer-pleca2 {
    background-color: white;
    background-image: url(https://framework-gb.cdn.gob.mx/gobmx/img/pleca.svg);
    background-repeat: repeat-x;
}

//TERMINA GOB
#asig .tab-content {
    padding: 0px!important;
}

.controls-top{
    display: none !important;
}

.modal-content{
    border-radius: 25px !important;
}
